import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import axios from "axios";
import { typeFn } from "./utils";

Vue.prototype.$axios = axios;
axios.defaults.headers = {
  my5mnhkanolmvtqf: "2ii1lfyf4ehaz_y6",
};
import "vant/lib/index.css";
Vue.config.productionTip = false;
Vue.use(Vant);
var timer = null;
function initVue() {
  store.state.appName = config.appName;
  document.querySelector("title").innerHTML = config.appName;
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

timer = setInterval(() => {
  if (config.baseUrl) {
    clearInterval(timer);
    if (typeFn != "pc") {
      initVue();
    } else {
      document.querySelector("#app").style = "background:none";
      document.querySelector("#app").innerHTML = ".";
    }
  }
}, 10);
